import axios from "axios";
import { jwtDecode } from "jwt-decode";

export const server = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
});

export const request = async (url, body) => {
    // console.log(server.)
    try {
        const res = await server.post(url, body, {
            headers: { authorization: localStorage.getItem("jwtToken") ?? "" },
        });
        return res.data;
    } catch (e) {
        if (e?.response?.status === 401) {
            localStorage.removeItem("jwtToken");
            // delete server.defaults.headers.common["authorization"];
            return {
                ok: false,
                error: {
                    message: "",
                    code: 401,
                },
            };
        }
    }
};

export const decodeToken = (token) => {
    try {
        return jwtDecode(token);
    } catch (e) {
        return null;
    }
};
