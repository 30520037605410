import { Navigate, useSearchParams } from "react-router-dom";
import React, { useContext } from "react";
import { TokenContext } from "../context";

export const ProcessToken = () => {
    const { setToken } = useContext(TokenContext);
    const [searchParams] = useSearchParams();

    const token = searchParams.get("token");

    if (!token) {
        return null;
    }

    localStorage.setItem("jwtToken", token);
    setToken(token);

    // return null
    return <Navigate to={"/dashboard"} />;
};
