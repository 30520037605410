import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { request } from "../utils";
import { Chat, Form } from "./VerifyAppointment";
import { io } from "socket.io-client";

export const EditVerification = () => {
    const navigate = useNavigate();
    const [appointment, setAppointment] = useState();
    const { appointmentId } = useParams();
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState("");
    const [newMessageLoading, setNewMessageLoading] = useState(false);
    useEffect(() => {
        let ignore = false;

        const getAppointment = async () => {
            const result = await request("/clinic/get-appointment", {
                id: appointmentId,
            });

            if (ignore) {
                return;
            }

            setMessages(result.messages);
            setAppointment(result);
        };

        getAppointment();

        return () => {
            ignore = true;
        };
    }, []);

    useEffect(() => {
        const socket = io(process.env.REACT_APP_BASE_URL, {
            transports: ["websocket"],
            autoConnect: false,
        });
        socket.auth = { jwt: localStorage.getItem("jwtToken") };
        // socket.auth = { jwt: "bad" };
        socket.connect();

        function onConnect() {
            console.log("Connected!");
        }

        function onDisconnect() {}

        function onUpdate(value) {
            setMessages(JSON.parse(value));
        }

        socket.on("connect", onConnect);
        socket.on("disconnect", onDisconnect);
        socket.on("chat_update", onUpdate);

        return () => {
            socket.off("connect", onConnect);
            socket.off("disconnect", onDisconnect);
            socket.off("chat_update", onUpdate);
            socket.disconnect();
        };
    }, []);

    const handleSave = async (form) => {
        await request("/clinic/save-bdv", {
            id: appointment.id,
            bdv: form,
        });
        navigate(-1);
    };
    const handleApprove = async (form) => {
        await request("/clinic/approve-bdv", {
            id: appointment.id,
            bdv: form,
        });
        navigate(-1);
    };

    const handleCancel = async () => {
        navigate(-1);
    };

    const handleNewMessage = async () => {
        if (newMessageLoading) {
            return;
        }
        setNewMessage("");
        await request("/clinic/new-message", {
            appointmentId: appointment.id,
            messageContent: newMessage,
        });
    };

    return (
        <div className="grid grid-cols-1 gap-x-12 overflow-auto pb-12 pt-1 md:grid-cols-4">
            <div className={"mt-4 px-2"}>
                {/*<Chat*/}
                {/*    handleNewMessage={handleNewMessage}*/}
                {/*    messages={messages}*/}
                {/*    newMessage={newMessage}*/}
                {/*    setNewMessage={setNewMessage}*/}
                {/*/>*/}
            </div>
            {appointment ? (
                <div className={"md:col-span-3"}>
                    <header className={"mb-4"}>
                        <div className="mx-auto max-w-7xl">
                            <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">
                                Edit Verification
                            </h1>
                        </div>
                    </header>
                    <Form
                        appointment={appointment}
                        handleSave={handleSave}
                        handleCancel={handleCancel}
                        handleApprove={handleApprove}
                        showApprovalButton={true}
                        // presentMode={true}
                    />
                </div>
            ) : (
                <div>Loading...</div>
            )}
        </div>
    );
};
