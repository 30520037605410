import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { format, isSameDay } from "date-fns";
import { request } from "../utils";
import { io } from "socket.io-client";
import { twMerge } from "tailwind-merge";
import {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
} from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { saveAs } from "file-saver";
import {
    PencilIcon,
    PaperAirplaneIcon,
    ChevronDownIcon,
    EyeIcon,
    ArrowDownTrayIcon,
} from "@heroicons/react/24/outline";
import { Tooltip } from "react-tooltip";

const getStatus = (status) => {
    if (status === "notSent") {
        return {
            text: "Not Sent",
            style: "text-[#2F2525] bg-[#F0EBEB]",
        };
    }

    if (["notReady", "readying", "ready", "inProgress"].includes(status)) {
        return {
            text: "In Progress",
            style: "text-[#A79657] bg-[#FFF7CD]",
        };
    }

    if (status === "complete") {
        return {
            text: "Verified",
            style: "text-[#22C55E] bg-[#DCFCE7]",
        };
    }

    if (status === "approved") {
        return {
            text: "Approved",
            style: "text-[#335564] bg-[#F2FAFE]",
        };
    }
};

const pageSize = 15;

const ClinicView = () => {
    const [appointments, setAppointments] = useState([]);
    const [location, setLocation] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const [metaData, setMetaData] = useState(null);
    const [loading, setLoading] = useState(true);

    const pageIndex = searchParams.get("pageIndex") ?? "0";
    const status = searchParams.get("status") ?? "All";
    const locationId = location?.id;

    useEffect(() => {
        const defaultSearchParams = {
            pageIndex: "0",
            status: "All",
        };

        let shouldSet = false;
        Object.entries(defaultSearchParams).forEach(([key, val]) => {
            if (!searchParams.has(key)) {
                searchParams.set(key, val);
                shouldSet = true;
            }
        });
        if (shouldSet) {
            setSearchParams(searchParams);
        }
    }, [searchParams, setSearchParams]);

    useEffect(() => {
        request(
            "/clinic/get-clinic-for-user",
            {},
            localStorage.getItem("jwtToken"),
        ).then((response) => {
            setLocation(response);
        });
    }, []);

    useEffect(() => {
        let ignore = false;

        if (!locationId) {
            return;
        }

        const getAppointments = async () => {
            const result = await request(
                "/clinic/get-appointments",
                {
                    locationId: locationId,
                    pageIndex: Number(pageIndex),
                    status: status,
                },
                localStorage.getItem("jwtToken"),
            );

            if (ignore) {
                return;
            }

            setAppointments(result.appointments);
            setMetaData(result.metaData);
        };
        setLoading(true);
        getAppointments().then(() => {
            setLoading(false);
        });

        return () => {
            ignore = true;
        };
    }, [locationId, pageIndex, status]);

    useEffect(() => {
        const socket = io(process.env.REACT_APP_BASE_URL, {
            transports: ["websocket"],
            autoConnect: false,
        });
        socket.auth = { jwt: localStorage.getItem("jwtToken") };

        socket.connect();

        function onConnect() {
            console.log("Connected!");
        }

        function onDisconnect() {}

        async function onUpdate() {
            const result = await request(
                "/clinic/get-appointments",
                {
                    locationId: locationId,
                    pageIndex: Number(pageIndex),
                    status: status,
                },
                localStorage.getItem("jwtToken"),
            );
            setAppointments(result.appointments);
            setMetaData(result.metaData);
        }

        socket.on("connect", onConnect);
        socket.on("disconnect", onDisconnect);
        socket.on("appointment_update", onUpdate);

        return () => {
            socket.off("connect", onConnect);
            socket.off("disconnect", onDisconnect);
            socket.off("appointment_update", onUpdate);
            socket.disconnect();
        };
    }, [locationId, pageIndex, status]);

    return (
        <div className={"h-full"}>
            <header>
                <div className={"grid grid-cols-3"}>
                    <div className="mt-[19px] sm:flex-auto">
                        <Link to={"/dashboard/clinic/add-patient"}>
                            <button
                                type="button"
                                className="rounded-md border-[2px] border-[#9C9C9C] py-[8px] pl-[6px] pr-[8px] text-[14px] font-medium text-[#6B6B6B] shadow-sm hover:bg-[#eaeaea] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
                            >
                                Add New Patient
                            </button>
                        </Link>
                    </div>
                    <div
                        className={
                            "flex w-full justify-center pt-[10px] text-[30px] font-bold leading-[44px] text-[#B3B3B3]"
                        }
                    >
                        {location?.name}
                    </div>
                    <div className={"flex justify-end"}>
                        <Listbox
                            className="mt-[84px] w-[320px]"
                            value={searchParams.get("status")}
                            onChange={(op) => {
                                searchParams.set("status", op);
                                searchParams.set("pageIndex", "0");
                                setSearchParams(searchParams);
                            }}
                        >
                            <div className="relative mt-2">
                                <ListboxButton className="relative h-[44px] w-full cursor-default rounded-md bg-white py-1.5 pl-[14px] pr-10 text-left font-poppins text-[16px] text-[#667085] shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-primary">
                                    <span className="block truncate">
                                        {searchParams.get("status")}
                                    </span>
                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                        <ChevronDownIcon
                                            aria-hidden="true"
                                            className="h-4 w-4 stroke-2 text-[#556987]"
                                        />
                                    </span>
                                </ListboxButton>

                                <ListboxOptions
                                    transition
                                    className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
                                >
                                    {[
                                        "Not Sent",
                                        "In Progress",
                                        "Verified",
                                        "Approved",
                                        "All",
                                    ].map((option) => (
                                        <ListboxOption
                                            key={option}
                                            value={option}
                                            className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-primary data-[focus]:text-white"
                                        >
                                            <span className="block truncate font-normal group-data-[selected]:font-semibold">
                                                {option}
                                            </span>

                                            <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-primary group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                                                <CheckIcon
                                                    aria-hidden="true"
                                                    className="h-5 w-5"
                                                />
                                            </span>
                                        </ListboxOption>
                                    ))}
                                </ListboxOptions>
                            </div>
                        </Listbox>
                    </div>
                </div>
            </header>
            {loading ? (
                <div
                    className={twMerge(
                        "flex w-full items-center justify-center pt-[10px]",
                    )}
                >
                    <div role="status">
                        <svg
                            aria-hidden="true"
                            className="h-8 w-8 animate-spin fill-primaryDark text-gray-200 dark:text-gray-300"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                            />
                            <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                            />
                        </svg>
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            ) : (
                <>
                    {appointments.map((appointment, index) => (
                        <div key={index}>
                            <TableRow appointment={appointment} />
                        </div>
                    ))}
                    {metaData && (
                        <nav
                            aria-label="Pagination"
                            className="mt-3 flex items-center justify-between border-gray-200 px-4 py-3 sm:px-6"
                        >
                            <div className="hidden sm:block">
                                <p className="text-sm text-gray-700">
                                    Showing{" "}
                                    <span className="font-medium">
                                        {metaData.pageIndex * pageSize + 1}
                                    </span>{" "}
                                    to{" "}
                                    <span className="font-medium">
                                        {Math.min(
                                            (metaData.pageIndex + 1) * pageSize,
                                            metaData.total,
                                        )}
                                    </span>{" "}
                                    of{" "}
                                    <span className="font-medium">
                                        {metaData.total}
                                    </span>{" "}
                                    results
                                </p>
                            </div>
                            <div className="flex flex-1 justify-between sm:justify-end">
                                {metaData.pageIndex > 0 ? (
                                    <button
                                        onClick={() => {
                                            if (metaData.pageIndex > 0) {
                                                searchParams.set(
                                                    "pageIndex",
                                                    String(
                                                        metaData.pageIndex - 1,
                                                    ),
                                                );
                                                setSearchParams(searchParams);
                                            }
                                        }}
                                        className="relative inline-flex cursor-pointer items-center px-3 py-2 text-sm font-semibold text-gray-900 hover:underline"
                                    >
                                        Previous
                                    </button>
                                ) : null}

                                {metaData.pageIndex + 1 <
                                metaData.totalPages ? (
                                    <button
                                        onClick={() => {
                                            if (
                                                metaData.pageIndex + 1 <
                                                metaData.totalPages
                                            ) {
                                                searchParams.set(
                                                    "pageIndex",
                                                    String(
                                                        metaData.pageIndex + 1,
                                                    ),
                                                );
                                                setSearchParams(searchParams);
                                            }
                                        }}
                                        className="relative inline-flex cursor-pointer items-center px-3 py-2 text-sm font-semibold text-gray-900 hover:underline"
                                    >
                                        Next
                                    </button>
                                ) : null}
                            </div>
                        </nav>
                    )}
                </>
            )}
        </div>
    );
};

const TableRow = ({ appointment }) => {
    const navigate = useNavigate();
    const [showActions, setShowActions] = useState(false);

    const handleDownload = async () => {
        try {
            const r = await fetch(
                `${process.env.REACT_APP_BASE_URL}/clinic/get-pdf?appointmentId=${appointment.id}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/octet-stream",
                        authorization: localStorage.getItem("jwtToken") ?? "",
                    },
                    responseType: "arraybuffer",
                },
            );

            const ab = await r.arrayBuffer();

            const blob = new Blob([ab], { type: "application/pdf" });
            const patientName = `${appointment.firstName} ${appointment.lastName}`;
            saveAs(blob, `${patientName}.pdf`);
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <div
            className={`mt-[10px] flex h-[37px] w-full ${appointment.status === "complete" || appointment.status === "notSent" ? "cursor-pointer" : ""} items-center justify-between rounded-md bg-white pl-[40px] pr-[32px] shadow hover:bg-[#F0EBEB]`}
            onMouseEnter={() => setShowActions(true)}
            onMouseLeave={() => setShowActions(false)}
            onClick={() => {
                if (
                    appointment.status === "notSent" ||
                    appointment.status === "complete"
                ) {
                    navigate(
                        `/dashboard/clinic/patients/edit-verification/${appointment.id}`,
                    );
                }
            }}
        >
            <div className={"flex items-center"}>
                <div className={"flex w-[500px] justify-between"}>
                    <div className={"text-[15px] font-[600] text-[#333F51]"}>
                        {`${appointment.firstName} ${appointment.lastName}`}
                    </div>
                    <div
                        className={twMerge(
                            "flex h-[24px] items-center rounded-2xl px-[10px] text-[12px] font-medium shadow-sm",
                            getStatus(appointment.status).style,
                        )}
                    >
                        {getStatus(appointment.status).text}
                    </div>
                </div>
                {showActions ? (
                    <div
                        className={
                            "ml-[35px] text-[13px] font-medium text-[#404040]"
                        }
                    >
                        Blue Cross Blue Shield
                    </div>
                ) : null}
            </div>

            <div className={"flex items-center"}>
                {showActions ? (
                    <>
                        <div
                            className={"text-[13px] font-medium text-[#000000]"}
                        >
                            Updated 46 minutes ago
                        </div>
                        {appointment.status === "complete" ? (
                            <>
                                <Tooltip
                                    id={`${appointment.id}-Download`}
                                    place={"top"}
                                    render={() => (
                                        <div>Download Verification Form</div>
                                    )}
                                ></Tooltip>
                                <button
                                    data-tooltip-id={`${appointment.id}-Download`}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleDownload();
                                    }}
                                >
                                    <ArrowDownTrayIcon
                                        className={
                                            "mx-[11px] h-[22px] cursor-pointer stroke-[#000000] stroke-2"
                                        }
                                    />
                                </button>
                                <Tooltip
                                    id={`${appointment.id}-View`}
                                    place={"top"}
                                    render={() => <div>View Patient</div>}
                                ></Tooltip>
                                <button
                                    data-tooltip-id={`${appointment.id}-View`}
                                    onClick={() =>
                                        navigate(
                                            `/dashboard/clinic/patients/edit-verification/${appointment.id}`,
                                        )
                                    }
                                >
                                    <EyeIcon
                                        className={
                                            "h-[22px] cursor-pointer stroke-[#000000] stroke-2"
                                        }
                                    />
                                </button>
                            </>
                        ) : null}
                        {appointment.status === "notSent" ? (
                            <>
                                <Tooltip
                                    id={`${appointment.id}-Edit`}
                                    place={"top"}
                                    render={() => <div>Edit Patient</div>}
                                ></Tooltip>
                                <button
                                    data-tooltip-id={`${appointment.id}-Edit`}
                                    onClick={() =>
                                        navigate(
                                            `/dashboard/clinic/patients/edit-verification/${appointment.id}`,
                                        )
                                    }
                                >
                                    <EyeIcon
                                        className={
                                            "mx-[11px] h-[22px] cursor-pointer stroke-[#000000] stroke-2"
                                        }
                                    />
                                </button>
                                <Tooltip
                                    id={`${appointment.id}-Send`}
                                    place={"top"}
                                    render={() => (
                                        <div>Send for Verification</div>
                                    )}
                                ></Tooltip>
                                <button
                                    data-tooltip-id={`${appointment.id}-Send`}
                                    onClick={async (e) => {
                                        e.stopPropagation();
                                        const shouldSend = window.confirm(
                                            `Are you sure you want to send ${appointment.firstName} ${appointment.lastName} for verification?`,
                                        );
                                        if (!shouldSend) {
                                            return;
                                        }

                                        await request(
                                            "/clinic/send-appointment-for-verification",
                                            {
                                                id: appointment.id,
                                            },
                                        );
                                    }}
                                >
                                    <PaperAirplaneIcon
                                        className={
                                            "h-[22px] cursor-pointer stroke-[#000000] stroke-2"
                                        }
                                    />
                                </button>
                            </>
                        ) : null}
                    </>
                ) : null}
            </div>
        </div>
    );
};

export default ClinicView;
