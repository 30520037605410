import { Link, useNavigate, useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { request } from "../utils";
import { io } from "socket.io-client";
import { twMerge } from "tailwind-merge";
import { Tooltip } from "react-tooltip";
import {
    ArrowDownTrayIcon,
    EyeIcon,
    PaperAirplaneIcon,
} from "@heroicons/react/24/outline";

const getStatus = (status) => {
    if (status === "notSent") {
        return {
            text: "Not Sent",
            style: "text-[#2F2525] bg-[#F0EBEB]",
        };
    }

    if (["notReady", "readying", "ready"].includes(status)) {
        return {
            text: "Ready",
            style: "text-[#2F2525] bg-[#F0EBEB]",
        };
    }

    if ("inProgress" === status) {
        return {
            text: "In Progress",
            style: "text-[#335564] bg-[#F2FAFE]",
        };
    }

    if (status === "complete") {
        return {
            text: "Verified",
            style: "text-[#22C55E] bg-[#DCFCE7]",
        };
    }

    if (status === "approved") {
        return {
            text: "Approved",
            style: "text-[#A79657] bg-[#FFF7CD]",
        };
    }
};

const pageSize = 15;

export const HeroPage = () => {
    const [appointments, setAppointments] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [metaData, setMetaData] = useState(null);
    const [loading, setLoading] = useState(true);

    const pageIndex = searchParams.get("pageIndex") ?? "0";
    const status = searchParams.get("status") ?? "All";

    useEffect(() => {
        const defaultSearchParams = {
            pageIndex: "0",
            status: "All",
        };

        let shouldSet = false;
        Object.entries(defaultSearchParams).forEach(([key, val]) => {
            if (!searchParams.has(key)) {
                searchParams.set(key, val);
                shouldSet = true;
            }
        });
        if (shouldSet) {
            setSearchParams(searchParams);
        }
    }, [searchParams, setSearchParams]);

    useEffect(() => {
        let ignore = false;

        const getAppointments = async () => {
            const result = await request(
                "/hero/get-appointments",
                {
                    pageIndex: Number(pageIndex),
                    status: status,
                },
                localStorage.getItem("jwtToken"),
            );

            if (ignore) {
                return;
            }

            setAppointments(result.appointments);
            setMetaData(result.metaData);
        };
        setLoading(true);
        getAppointments().then(() => {
            setLoading(false);
        });

        return () => {
            ignore = true;
        };
    }, [pageIndex, status]);

    useEffect(() => {
        const socket = io(process.env.REACT_APP_BASE_URL, {
            transports: ["websocket"],
            autoConnect: false,
        });
        socket.auth = { jwt: localStorage.getItem("jwtToken") };

        socket.connect();

        function onConnect() {
            console.log("Connected!");
        }

        function onDisconnect() {}

        async function onUpdate() {
            const result = await request(
                "/hero/get-appointments",
                {
                    pageIndex: Number(pageIndex),
                    status: status,
                },
                localStorage.getItem("jwtToken"),
            );
            setAppointments(result.appointments);
            setMetaData(result.metaData);
        }

        socket.on("connect", onConnect);
        socket.on("disconnect", onDisconnect);
        socket.on("appointment_update", onUpdate);

        return () => {
            socket.off("connect", onConnect);
            socket.off("disconnect", onDisconnect);
            socket.off("appointment_update", onUpdate);
            socket.disconnect();
        };
    }, [pageIndex, status]);

    return (
        <div className={"h-full"}>
            <div className={"flex justify-center"}>
                <div
                    className={
                        "flex w-full justify-center pt-[22px] text-[30px] font-bold leading-[44px] text-[#B3B3B3]"
                    }
                >
                    Hero Dashboard
                </div>
            </div>
            <div className={"pt-[18px]"}>
                {loading ? (
                    <div
                        className={twMerge(
                            "flex w-full items-center justify-center pt-[10px]",
                        )}
                    >
                        <div role="status">
                            <svg
                                aria-hidden="true"
                                className="h-8 w-8 animate-spin fill-primaryDark text-gray-200 dark:text-gray-300"
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="currentColor"
                                />
                                <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentFill"
                                />
                            </svg>
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                ) : (
                    <div>
                        {appointments.map((appointment, index) => (
                            <div key={index}>
                                <TableRow appointment={appointment} />
                            </div>
                        ))}
                        {metaData && (
                            <nav
                                aria-label="Pagination"
                                className="mt-3 flex items-center justify-between border-gray-200 px-4 py-3 sm:px-6"
                            >
                                <div className="hidden sm:block">
                                    <p className="text-sm text-gray-700">
                                        Showing{" "}
                                        <span className="font-medium">
                                            {metaData.pageIndex * pageSize + 1}
                                        </span>{" "}
                                        to{" "}
                                        <span className="font-medium">
                                            {Math.min(
                                                (metaData.pageIndex + 1) *
                                                    pageSize,
                                                metaData.total,
                                            )}
                                        </span>{" "}
                                        of{" "}
                                        <span className="font-medium">
                                            {metaData.total}
                                        </span>{" "}
                                        results
                                    </p>
                                </div>
                                <div className="flex flex-1 justify-between sm:justify-end">
                                    {metaData.pageIndex > 0 ? (
                                        <button
                                            onClick={() => {
                                                if (metaData.pageIndex > 0) {
                                                    searchParams.set(
                                                        "pageIndex",
                                                        String(
                                                            metaData.pageIndex -
                                                                1,
                                                        ),
                                                    );
                                                    setSearchParams(
                                                        searchParams,
                                                    );
                                                }
                                            }}
                                            className="relative inline-flex cursor-pointer items-center px-3 py-2 text-sm font-semibold text-gray-900 hover:underline"
                                        >
                                            Previous
                                        </button>
                                    ) : null}

                                    {metaData.pageIndex + 1 <
                                    metaData.totalPages ? (
                                        <button
                                            onClick={() => {
                                                if (
                                                    metaData.pageIndex + 1 <
                                                    metaData.totalPages
                                                ) {
                                                    searchParams.set(
                                                        "pageIndex",
                                                        String(
                                                            metaData.pageIndex +
                                                                1,
                                                        ),
                                                    );
                                                    setSearchParams(
                                                        searchParams,
                                                    );
                                                }
                                            }}
                                            className="relative inline-flex cursor-pointer items-center px-3 py-2 text-sm font-semibold text-gray-900 hover:underline"
                                        >
                                            Next
                                        </button>
                                    ) : null}
                                </div>
                            </nav>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

const TableRow = ({ appointment }) => {
    const navigate = useNavigate();

    return (
        <div
            className={`mt-[10px] flex h-[37px] w-full ${appointment.status === "ready" ? "cursor-pointer" : ""} items-center justify-between rounded-md bg-white pl-[40px] pr-[32px] shadow hover:bg-[#F0EBEB]`}
            onClick={() => {
                if (appointment.status === "ready") {
                    navigate(
                        `/dashboard/hero/verify-appointment/${appointment.id}`,
                    );
                }
            }}
        >
            <div className={"flex items-center"}>
                <div className={"flex w-[500px] justify-between"}>
                    <div className={"text-[15px] font-[600] text-[#333F51]"}>
                        {`${appointment.firstName} ${appointment.lastName}`}
                    </div>
                    <div
                        className={twMerge(
                            "flex h-[24px] items-center rounded-2xl px-[10px] text-[12px] font-medium shadow-sm",
                            getStatus(appointment.status).style,
                        )}
                    >
                        {getStatus(appointment.status).text}
                    </div>
                </div>
            </div>
        </div>
    );
};
