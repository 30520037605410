import { Link, useNavigate } from "react-router-dom";
import {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
} from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import React, { Fragment } from "react";
import { format } from "date-fns";
import { useForm } from "react-hook-form";
import { request } from "../utils";
import { ErrorMessage } from "@hookform/error-message";

const dateForDateTimeInputValue = (date) =>
    new Date(date.getTime() + new Date().getTimezoneOffset() * -60 * 1000)
        .toISOString()
        .slice(0, 19);

export const AddPatient = () => {
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors },
        getValues,
    } = useForm({
        defaultValues: {
            firstName: "",
            lastName: "",
            dateOfBirth: "",
            memberId: "",
            providerNpi: "",
            payerId: "",
            date: dateForDateTimeInputValue(new Date()),
        },
    });

    const handleSave = async (form) => {
        await request("/clinic/create-appointment", {
            form,
        });
        navigate(-1);
    };

    const handleCancel = async () => {
        if (window.confirm("Are you sure you want to cancel?")) {
            navigate(-1);
        }
    };

    return (
        <div className={"mx-auto max-w-7xl sm:px-6 lg:px-8"}>
            <header className={"mb-4"}>
                <div className="mx-auto max-w-7xl">
                    <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">
                        Add Patient
                    </h1>
                </div>
            </header>
            <form>
                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div className="sm:col-span-2">
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            First Name
                        </label>
                        <div className="mt-2">
                            <input
                                {...register("firstName", {
                                    required: "Required",
                                })}
                                className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                            />
                            <ErrorMessage errors={errors} name="firstName" />
                        </div>
                    </div>

                    <div className="sm:col-span-2">
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Last Name
                        </label>
                        <div className="mt-2">
                            <input
                                {...register("lastName", {
                                    required: "Required",
                                })}
                                className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                            />
                            <ErrorMessage errors={errors} name="lastName" />
                        </div>
                    </div>

                    <div className="sm:col-span-2">
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Date of Birth
                        </label>
                        <div className="mt-2">
                            <input
                                type={"date"}
                                {...register("dateOfBirth", {
                                    required: "Required",
                                })}
                                className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                            />
                            <ErrorMessage errors={errors} name="dateOfBirth" />
                        </div>
                    </div>
                    <div className="sm:col-span-2">
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Member ID
                        </label>
                        <div className="mt-2">
                            <input
                                {...register("memberId")}
                                className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                            />
                            <ErrorMessage errors={errors} name="memberId" />
                        </div>
                    </div>

                    <div className="sm:col-span-2">
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Payer ID
                        </label>
                        <div className="mt-2">
                            <input
                                {...register("payerId")}
                                className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                            />
                            <ErrorMessage errors={errors} name="payerId" />
                        </div>
                    </div>
                    <div className="sm:col-span-2">
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Appointment Time
                        </label>
                        <div className="mt-2">
                            <input
                                type={"datetime-local"}
                                {...register("date", {
                                    required: "Required",
                                })}
                                className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                            />
                            <ErrorMessage errors={errors} name="date" />
                        </div>
                    </div>
                    <div className="sm:col-span-2">
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Provider NPI
                        </label>
                        <div className="mt-2">
                            <input
                                {...register("providerNpi")}
                                className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                            />
                            <ErrorMessage errors={errors} name="providerNpi" />
                        </div>
                    </div>
                    {/*<div className={"col-span-5"}></div>*/}
                    <div className="mt-6 flex items-center justify-end gap-x-6">
                        <button
                            onClick={handleCancel}
                            type="button"
                            className="cursor-pointer text-sm font-semibold leading-6 text-gray-900"
                        >
                            Cancel
                        </button>

                        <button
                            onClick={handleSubmit(handleSave)}
                            type="button"
                            className="cursor-pointer rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primaryLight focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};
