import React, { useContext, useEffect } from "react";
import { request } from "../utils";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import logo from "../SVG/PatientPass_Primary_Red.svg";
import { TokenContext } from "../context";

export const LoginPage = () => {
    const { token, setToken } = useContext(TokenContext);
    const navigate = useNavigate();
    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm({
        resolver: zodResolver(
            z.object({
                email: z.string().email(),
                password: z.string(),
            }),
        ),
    });

    const login = async (data) => {
        const r = await request("/login", {
            email: data.email,
            password: data.password,
        });

        localStorage.setItem("jwtToken", r);
        setToken(r);
    };

    useEffect(() => {
        if (token) {
            navigate("/dashboard");
        }
    }, [token, navigate]);

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <div className="flex h-16 shrink-0 items-center justify-center">
                    <img src={logo} />
                </div>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <form className="space-y-6" onSubmit={handleSubmit(login)}>
                    <div>
                        <label
                            htmlFor="email"
                            className="block text-sm font-medium leading-6 text-gray-900"
                        >
                            Email Address
                        </label>
                        <div className="mt-2">
                            <input
                                {...register("email")}
                                // type="email"
                                autoComplete="email"
                                className="focus:ring-forrestGreen block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>
                    <div>
                        <label
                            htmlFor="password"
                            className="block text-sm font-medium leading-6 text-gray-900"
                        >
                            Password
                        </label>
                        <div className="mt-2">
                            <input
                                {...register("password")}
                                type="password"
                                autoComplete="password"
                                className="focus:ring-forrestGreen block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>

                    <div>
                        <button
                            className="w-full rounded-md bg-primary px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primaryLight focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
                            type={"submit"}
                        >
                            Log In
                        </button>

                        {/*{errors.email?.message &&*/}
                        {/*    typeof errors.email.message === "string" && (*/}
                        {/*        <ErrorMessage>*/}
                        {/*            {errors.email.message}*/}
                        {/*        </ErrorMessage>*/}
                        {/*    )}*/}
                    </div>
                </form>
            </div>
        </div>
    );
};

export default LoginPage;
