import { request } from "../utils";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Device } from "@twilio/voice-sdk";
import { io } from "socket.io-client";
import {
    PaperAirplaneIcon,
    PhoneIcon,
    PhoneXMarkIcon,
} from "@heroicons/react/20/solid";
import { BackspaceIcon } from "@heroicons/react/24/outline";
import { useStayAtBottom } from "react-stay-at-bottom";
import { format } from "date-fns";
import { twMerge } from "tailwind-merge";

const VoiceCall = ({ phoneNumberToCallFrom }) => {
    const [phoneNumber, setPhoneNumber] = useState("+1");
    const [device, setDevice] = useState(null);
    const [call, setCall] = useState(null);
    // dialing | calling | muted
    const [state, setState] = useState("dialing");

    useEffect(() => {
        let ignore = false;

        const fetchToken = async () => {
            try {
                const response = await request(
                    "/hero/get-twilio-access-token",
                    {},
                    localStorage.getItem("jwtToken"),
                );

                console.log(response.token);

                if (ignore) {
                    return;
                }

                const device = new Device(response.token, {
                    enableImprovedSignalingErrorPrecision: true,
                    codecPreferences: ["opus", "pcmu"],
                });
                setDevice(device);
            } catch (error) {
                console.error("Error fetching token:", error);
            }
        };
        fetchToken();

        return () => {
            ignore = true;
        };
    }, []);

    const handleCall = async () => {
        if (state === "calling" || state === "muted") {
            return;
        }
        try {
            if (!device) {
                return;
            }

            const params = {
                To: phoneNumber,
                // callerId: "+19513836491",
                callerId: phoneNumberToCallFrom,
            };

            console.log(params);

            const call = await device.connect({ params });
            setState("calling");

            setCall(call);
            call.on("accept", () => {
                console.log({ call });
            });
            call.on("ringing", () => {});
            call.on("answered", () => {});
            call.on("connected", () => {});
            call.on("disconnect", () => {});
            call.on("cancel", () => {});
        } catch (error) {
            setState("dialing");
            console.log(error);
            // console.log(error);
        }
    };

    const handleDialPad = (digit) => {
        if (state === "dialing") {
            setPhoneNumber((prev) => prev + digit);
        } else {
            call.sendDigits(digit);
        }
    };

    const hangUp = () => {
        if (state !== "calling" && state !== "muted") {
            return;
        }
        call.disconnect();
        setState("dialing");
    };

    const handleMute = () => {
        if (state === "calling") {
            call.mute(true);
            setState("muted");
        } else if (state === "muted") {
            call.mute(false);
            setState("calling");
        }
    };

    return (
        <div className={"w-full rounded-lg bg-white pb-10"}>
            <input
                className={
                    "mb-3 block h-12 w-full rounded-md border-0 text-center text-xl shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary"
                }
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
            />

            <div className={"grid grid-cols-3 gap-3"}>
                {[
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "9",
                    "*",
                    "0",
                    "#",
                ].map((dig) => (
                    <button
                        key={dig}
                        onClick={() => handleDialPad(dig)}
                        type="button"
                        className="h-12 w-full rounded-full border-0 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                        {dig}
                        {/*<PlusIcon aria-hidden="true" className="h-6 w-6" />*/}
                    </button>
                ))}
                {state !== "dialing" ? (
                    <button
                        className={
                            "h-12 w-full rounded-md border-0 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300"
                        }
                        onClick={() => handleMute()}
                    >
                        {state === "muted" ? "Unmute" : "Mute"}
                    </button>
                ) : null}
                <div />
                {state === "dialing" ? (
                    <button
                        className={
                            "flex h-12 items-center justify-center rounded-md bg-[#60CD61] shadow-sm hover:bg-[#8fdc90]"
                        }
                        onClick={() => handleCall()}
                    >
                        <PhoneIcon className={"h-6 fill-white"} />{" "}
                    </button>
                ) : null}

                {phoneNumber.length && state === "dialing" ? (
                    <button
                        className={"flex items-center justify-center"}
                        onClick={() =>
                            setPhoneNumber((prevState) =>
                                prevState.slice(0, prevState.length - 1),
                            )
                        }
                    >
                        <BackspaceIcon className={"h-7 stroke-gray-500"} />
                    </button>
                ) : state !== "dialing" ? (
                    <button
                        className={
                            "flex h-12 items-center justify-center rounded-md bg-red-500 shadow-sm"
                        }
                        onClick={() => hangUp()}
                    >
                        <PhoneXMarkIcon className={"h-6 fill-white"} />
                    </button>
                ) : null}
            </div>

            {/*<h1>Make a Voice Call</h1>*/}
            {/*<input*/}
            {/*    type="text"*/}
            {/*    value={phoneNumber}*/}
            {/*    onChange={(e) => setPhoneNumber(e.target.value)}*/}
            {/*    placeholder="Enter phone number"*/}
            {/*/>*/}
            {/*<div>*/}
            {/*    <button onClick={handleCall}>Call</button>*/}
            {/*</div>*/}
            {/*<div>*/}
            {/*    <button onClick={hangUp}>Hang Up</button>*/}
            {/*</div>*/}
            {/*<div>*/}
            {/*    <button onClick={() => call.sendDigits("2")}>2</button>*/}
            {/*</div>*/}
        </div>
    );
};

export const Chat = ({
    messages,
    newMessage,
    setNewMessage,
    handleNewMessage,
}) => {
    const scrollRef = useRef(null);
    const { stayAtBottom, stopAtBottom, scrollToBottom, atBottom, scroll } =
        useStayAtBottom(scrollRef, { autoStay: true, initialStay: true });

    return (
        <div
            className={
                "grid h-96 grid-cols-1 grid-rows-[1fr_auto] rounded-lg border border-gray-500 bg-[#F7F8F9]"
            }
        >
            <div className={"overflow-y-scroll px-2 pt-4"} ref={scrollRef}>
                {messages.map((message, index) => (
                    <div key={index} className={"mb-6"}>
                        <div
                            className={
                                "flex flex-row space-x-2 pb-1 pl-3 text-sm text-gray-500"
                            }
                        >
                            <div>{message.userName}</div>
                            <div>
                                {format(message.date, "MMM dd") +
                                    " at " +
                                    format(message.date, "h:mm a")}
                            </div>
                        </div>

                        <div className={"rounded-md bg-gray-200 p-3"}>
                            {message.content}
                        </div>
                    </div>
                ))}
            </div>
            <div className={"mt-auto flex flex-row"}>
                <textarea
                    placeholder={"Send Message"}
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    className="block h-8 w-full resize-none rounded-md border-0 px-2 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                />
                <button
                    onClick={handleNewMessage}
                    type="button"
                    className="inline-flex items-center gap-x-1.5 rounded-md bg-primary px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-primaryLight focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
                >
                    <PaperAirplaneIcon className="mr-0.5 h-5 w-5" />
                </button>
            </div>
        </div>
    );
};

export const VerifyAppointment = () => {
    const navigate = useNavigate();
    const [appointment, setAppointment] = useState(null);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState("");
    const [newMessageLoading, setNewMessageLoading] = useState(false);
    const params = useParams();
    const [location, setLocation] = useState(null);

    useEffect(() => {
        if (!appointment) {
            return;
        }
        request("/hero/get-location", {
            locationId: appointment.locationId,
        }).then(setLocation);
    }, [appointment]);

    useEffect(() => {
        let ignore = false;

        const getAppointment = async () => {
            const result = await request("/hero/checkout-appointment", {
                id: params.appointmentId,
            });

            if (ignore) {
                return;
            }

            if (result) {
                console.log(result);
                setMessages(result.messages);
                setAppointment(result);
            }
        };

        getAppointment().catch(() => {
            navigate("/hero");
        });

        return () => {
            ignore = true;
        };
    }, []);

    useEffect(() => {
        const socket = io(process.env.REACT_APP_BASE_URL, {
            transports: ["websocket"],
            autoConnect: false,
        });
        socket.auth = { jwt: localStorage.getItem("jwtToken") };
        // socket.auth = { jwt: "bad" };
        socket.connect();

        function onConnect() {
            console.log("Connected!");
        }

        function onDisconnect() {}

        function onUpdate(value) {
            setMessages(JSON.parse(value));
        }

        socket.on("connect", onConnect);
        socket.on("disconnect", onDisconnect);
        socket.on("chat_update", onUpdate);

        return () => {
            socket.off("connect", onConnect);
            socket.off("disconnect", onDisconnect);
            socket.off("chat_update", onUpdate);
            socket.disconnect();
        };
    }, []);

    const handleNewMessage = async () => {
        if (newMessageLoading) {
            return;
        }
        setNewMessage("");
        await request("/hero/new-message", {
            appointmentId: appointment.id,
            messageContent: newMessage,
        });
    };

    const handleSave = async (form) => {
        await request("/hero/save-form", {
            id: appointment.id,
            form: form,
        });
        navigate(-1);
    };

    const handleCancel = async () => {
        if (
            window.confirm(
                "Are you sure you want to forfeit this verification?",
            )
        ) {
            await request("/hero/release-appointment", { id: appointment.id });
            navigate(-1);
        }
    };

    return (
        <div className="grid grid-cols-[300px_1fr_350px] gap-x-4 overflow-auto bg-gray-200">
            <div className={"col-span-1 h-full bg-[#FEA9AB] px-4"}>
                <div className={"mt-6"}>
                    {location ? (
                        <VoiceCall
                            phoneNumberToCallFrom={location.phoneNumber}
                        />
                    ) : null}
                </div>
            </div>
            {appointment ? (
                <div className={""}>
                    <div
                        className={
                            "mb-4 mt-2 rounded-md border border-gray-500 bg-white px-4 py-3"
                        }
                    >
                        <Form
                            appointment={appointment}
                            handleSave={handleSave}
                            handleCancel={handleCancel}
                        />
                    </div>
                </div>
            ) : (
                <div>Loading...</div>
            )}
            <div className={"bg-white pl-2 pr-5 pt-2"}>
                {(location?.usefulInformation ?? []).map((s) => {
                    return <div className={"text-end leading-5"}>{s}</div>;
                })}
                {/*<Chat*/}
                {/*    handleNewMessage={handleNewMessage}*/}
                {/*    messages={messages}*/}
                {/*    newMessage={newMessage}*/}
                {/*    setNewMessage={setNewMessage}*/}
                {/*/>*/}
            </div>
        </div>
    );
};

export const Form = ({
    appointment,
    handleSave,
    handleCancel,
    showApprovalButton,
    handleApprove,
    showButtons = true,
    presentMode = false,
}) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: appointment.form,
    });

    // console.log(appointment);

    const render = (fields, path) => {
        return fields.map((field, index) => {
            if (field.type === "header") {
                return (
                    <h2
                        key={index}
                        className="col-span-12 text-[14px] font-bold leading-5 text-gray-900"
                    >
                        {field.label}
                    </h2>
                );
            }
            if (field.type === "subheader") {
                return (
                    <h3
                        key={index}
                        className={"text-[13px]"}
                        style={{ gridColumn: `span ${field.width}` }}
                    >
                        {field.label}
                    </h3>
                );
            }
            if (field.type === "input") {
                const name = path + `.${index}.value`;
                const renderInput = () => {
                    if (field.inputType === "text") {
                        return (
                            <input
                                {...register(name)}
                                className={twMerge(
                                    "focus:border-b-1 block w-full border-x-0 border-t-0 border-dashed border-gray-400 px-0 py-0 text-[12px] text-gray-900 ring-0 focus:border-x-0 focus:border-t-0 focus:border-b-primaryDark focus:outline-none focus:ring-0",
                                    presentMode && "border-0",
                                )}
                                type={"text"}
                            />
                        );
                    }
                    if (field.inputType === "checkbox") {
                        return (
                            <input
                                {...register(name)}
                                type={"checkbox"}
                                className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-0"
                            />
                        );
                    }
                    if (field.inputType === "select") {
                        return (
                            <select
                                {...register(name)}
                                className={twMerge(
                                    "block w-full border-x-0 border-t-0 border-dashed border-b-gray-500 px-2 py-1 text-[12px] text-gray-900 ring-0 focus:border-x-0 focus:border-t-0 focus:border-b-primaryDark focus:outline-none focus:ring-0",
                                    presentMode && "border-0 bg-none",
                                )}
                            >
                                {field.options.map((option) => (
                                    <option key={option}>{option}</option>
                                ))}
                            </select>
                        );
                    }
                    if (field.inputType === "radio") {
                        return (
                            <div className="space-y-6 sm:flex sm:items-center sm:space-x-6 sm:space-y-0">
                                {field.options.map((option) => (
                                    <div
                                        key={option}
                                        className="flex items-center"
                                    >
                                        <input
                                            {...register(name)}
                                            type="radio"
                                            className="h-4 w-4 border-gray-300 text-primary focus:outline-none focus:ring-0"
                                        />
                                        <label className="ml-2 block text-[12px] leading-6 text-gray-900">
                                            {option}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        );
                    }
                };

                return (
                    <div
                        key={index}
                        style={{ gridColumn: `span ${field.width}` }}
                        className={"flex flex-row items-center space-x-2"}
                    >
                        <label
                            className={twMerge(
                                "whitespace-nowrap text-[12px] text-gray-900",
                                //presentMode && "text-[10px]",
                            )}
                        >
                            {field.label}:
                        </label>

                        {renderInput()}
                    </div>
                );
            }
            if (field.type === "spacer") {
                return (
                    <div
                        key={index}
                        style={{ gridColumn: `span ${field.width}` }}
                    ></div>
                );
            }
            if (field.type === "newline") {
                return <div key={index} style={{ gridColumnEnd: `-1` }}></div>;
            }
        });
    };

    return (
        <>
            <form className={"grid grid-cols-12 gap-x-2 gap-y-2"}>
                {render(appointment.form.fields, "fields")}
                {showButtons && (
                    <div className="col-span-12 mt-6 flex items-center justify-end gap-x-6">
                        <button
                            onClick={handleCancel}
                            type="button"
                            className="cursor-pointer text-sm font-semibold leading-6 text-gray-900"
                        >
                            Cancel
                        </button>

                        <button
                            onClick={handleSubmit(handleSave)}
                            type="button"
                            className="cursor-pointer rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primaryLight focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
                        >
                            Submit
                        </button>
                        {showApprovalButton && (
                            <button
                                onClick={handleSubmit(handleApprove)}
                                type="button"
                                className="cursor-pointer rounded-md bg-green-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-500"
                            >
                                Approve
                            </button>
                        )}
                    </div>
                )}
            </form>
        </>
    );
};
