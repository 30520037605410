import {
    Navigate,
    NavLink,
    Outlet,
    useLocation,
    useNavigate,
} from "react-router-dom";
import { decodeToken, request, server } from "../utils";
import { useEffect, useRef, useState } from "react";
import logo from "../SVG/PatientPass_Logomark_Wht.svg";
import { twMerge } from "tailwind-merge";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export const Dashboard = () => {
    const location = useLocation();
    const [navigation, setNavigation] = useState([]);

    const token = localStorage.getItem("jwtToken");
    const decoded = decodeToken(token);

    useEffect(() => {
        if (decoded?.type === "HERO") {
            setNavigation([{ name: "Queue", href: "/dashboard/hero" }]);
        } else if (decoded?.type === "CLINIC") {
            setNavigation([
                {
                    name: "Dashboard",
                    href: "/dashboard/clinic/patients",
                },
            ]);
        }
    }, [decoded?.type]);

    const shouldRenderSidebar =
        !location.pathname.includes("verify-appointment");
    return (
        <div className={"h-screen"}>
            {shouldRenderSidebar && (
                <div className="fixed inset-y-0 z-50 flex w-[149px] flex-col">
                    <div className="flex grow flex-col overflow-y-auto bg-gradient-to-t from-[#FE888B] to-[#FDCACB] pl-[9px]">
                        <div className="mb-[32px] mt-5 flex h-16 shrink-0 items-center justify-center">
                            <img src={logo} className="h-16 w-auto" />
                        </div>
                        <nav className="flex flex-1 flex-col">
                            <ul className="flex flex-1 flex-col">
                                <li>
                                    <ul>
                                        {navigation.map((item) => (
                                            <li key={item.name}>
                                                <NavLink
                                                    to={item.href}
                                                    className={({ isActive }) =>
                                                        classNames(
                                                            isActive
                                                                ? "justify-center bg-[#fbdbda]"
                                                                : "pl-[9px] hover:bg-[#f7b9b7]",
                                                            "flex h-[48px] w-[116px] items-center rounded-lg text-[16px] font-medium leading-6",
                                                        )
                                                    }
                                                >
                                                    {item.name}
                                                </NavLink>
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                                <li className="mb-[85px] mt-auto flex h-[48px] w-[116px] cursor-pointer items-center rounded-lg pl-[7px] hover:bg-[#f18581]">
                                    <button
                                        onClick={() => {
                                            localStorage.removeItem("jwtToken");
                                            window.location.reload();
                                        }}
                                    >
                                        Log Out
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            )}

            <div
                className={twMerge(
                    "min-h-full",
                    shouldRenderSidebar
                        ? "ml-[149px] bg-[#F9F7F7] pl-[52px] pr-[32px]"
                        : "ml-0",
                )}
            >
                <Outlet></Outlet>
            </div>
        </div>
    );
};
