import React, { createContext, useEffect, useState } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import { Dashboard } from "./pages/Dashboard";
import ClinicView from "./pages/ClinicView";
import { VerifyAppointment } from "./pages/VerifyAppointment";
import { HeroPage } from "./pages/HeroPage";
import { EditVerification } from "./pages/EditVerification";
import LoginEmailSent from "./pages/LoginEmailSent";
import { ProcessToken } from "./pages/ProcessToken";
import { decodeToken } from "./utils";
import { Pdf } from "./pages/Pdf";
import { TokenContext } from "./context";
import { AddPatient } from "./pages/AddPatient";
import { TestRenderForm } from "./pages/TestRenderForm";

function App() {
    const [token, setToken] = useState(localStorage.getItem("jwtToken"));
    // const token = localStorage.getItem("jwtToken");
    const decoded = decodeToken(token);
    const userType = decoded?.type;

    return (
        <TokenContext.Provider value={{ token, setToken }}>
            <div className="App h-screen">
                <Routes>
                    <Route path={"/up"} element={<div>"Up!</div>} />

                    {token ? null : (
                        <>
                            <Route
                                path="/pdf/:appointmentId"
                                element={<Pdf />}
                            />
                            {/*<Route*/}
                            {/*    path="/test-render-form"*/}
                            {/*    element={<TestRenderForm />}*/}
                            {/*/>*/}
                            <Route path="/login" element={<LoginPage />} />
                            <Route
                                path={"/process-token"}
                                element={<ProcessToken />}
                            ></Route>
                        </>
                    )}

                    {token ? (
                        <Route path="/dashboard" element={<Dashboard />}>
                            {userType === "HERO" ? (
                                <>
                                    <Route
                                        path={"hero"}
                                        element={<HeroPage />}
                                    />
                                    <Route
                                        path="hero/verify-appointment/:appointmentId"
                                        element={<VerifyAppointment />}
                                    />
                                    <Route
                                        path={"/dashboard"}
                                        element={<Navigate to={"hero"} />}
                                    ></Route>
                                    <Route
                                        path={"*"}
                                        element={<Navigate to={"hero"} />}
                                    ></Route>
                                </>
                            ) : null}
                            {userType === "CLINIC" ? (
                                <>
                                    <Route
                                        path={"clinic/patients"}
                                        element={<ClinicView />}
                                    />
                                    <Route
                                        path={"clinic/calendar"}
                                        element={<h1>Calendar Coming Soon</h1>}
                                    />
                                    <Route
                                        path={
                                            "clinic/patients/edit-verification/:appointmentId"
                                        }
                                        element={<EditVerification />}
                                    />
                                    <Route
                                        path={"clinic/add-patient"}
                                        element={<AddPatient />}
                                    />
                                    <Route
                                        path={"/dashboard"}
                                        element={
                                            <Navigate
                                                to={
                                                    "/dashboard/clinic/patients"
                                                }
                                            />
                                        }
                                    />
                                    <Route
                                        path={"*"}
                                        element={
                                            <Navigate
                                                to={
                                                    "/dashboard/clinic/patients"
                                                }
                                            />
                                        }
                                    />
                                </>
                            ) : null}
                        </Route>
                    ) : null}
                    <Route
                        path={"*"}
                        element={
                            token ? (
                                <Navigate to={"/dashboard"} />
                            ) : (
                                <Navigate to={"/login"} />
                            )
                        }
                    ></Route>
                </Routes>
            </div>
        </TokenContext.Provider>
    );
}

export default App;
