import { request } from "../utils";
import { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useParams } from "react-router-dom";
import { Form } from "./VerifyAppointment";
import { saveAs } from "file-saver";

export const Pdf = () => {
    const { appointmentId } = useParams();
    const [appointment, setAppointment] = useState(null);

    useEffect(() => {
        let ignore = false;

        const getAppointment = async () => {
            const result = await request("/get-appointment-for-pdf", {
                id: appointmentId,
            });

            if (ignore) {
                return;
            }

            setAppointment(result);
        };

        getAppointment();

        return () => {
            ignore = true;
        };
    }, []);

    return (
        <div className="w-[800px]" id={"pdf-page"}>
            {appointment ? (
                <div className={"md:col-span-3"} id={"pdf"}>
                    <Form
                        appointment={appointment}
                        showButtons={false}
                        presentMode={true}
                    />
                </div>
            ) : (
                <div>Loading...</div>
            )}
        </div>
    );
};
